.terms-and-conditions ol.lst-kix_list_1-3 {
  list-style-type: none;
}
.terms-and-conditions ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.terms-and-conditions ol.lst-kix_list_1-1 {
  list-style-type: none;
}
.terms-and-conditions ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.terms-and-conditions .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.terms-and-conditions ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
.terms-and-conditions ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.terms-and-conditions .lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
.terms-and-conditions ul.lst-kix_list_1-4 {
  list-style-type: none;
}
.terms-and-conditions ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.terms-and-conditions .lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) ' ';
}
.terms-and-conditions .lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, upper-latin) ') ';
}
.terms-and-conditions ul.lst-kix_list_1-8 {
  list-style-type: none;
}
.terms-and-conditions ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.terms-and-conditions ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.terms-and-conditions .lst-kix_list_1-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-3, decimal) ') ';
}
.terms-and-conditions .lst-kix_list_1-4 > li:before {
  content: '\002022   ';
}
.terms-and-conditions ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.terms-and-conditions .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.terms-and-conditions .lst-kix_list_1-7 > li:before {
  content: '\002022   ';
}
.terms-and-conditions .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.terms-and-conditions .lst-kix_list_1-5 > li:before {
  content: '\002022   ';
}
.terms-and-conditions .lst-kix_list_1-6 > li:before {
  content: '\002022   ';
}
.terms-and-conditions li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  font-size: 11pt;
  min-width: 36pt;
}
.terms-and-conditions .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.terms-and-conditions .lst-kix_list_1-8 > li:before {
  content: '\002022   ';
}
.terms-and-conditions ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.terms-and-conditions ol {
  margin: 0;
  padding: 0;
}
table td,
.terms-and-conditions table th {
  padding: 0;
}
.terms-and-conditions .c36 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: normal;
}
.terms-and-conditions .c11 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: normal;
}

.terms-and-conditions .c5 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.terms-and-conditions .c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.terms-and-conditions .c9 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}

.terms-and-conditions .c80 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: italic;
}

.terms-and-conditions .c8 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: italic;
}
.terms-and-conditions .c26 {
  margin-left: 41pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1.08;
  text-align: left;
  margin-right: 6pt;
}

.terms-and-conditions .c24 {
  margin-left: 41pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1.08;
  text-align: justify;
  margin-right: 6pt;
}
.terms-and-conditions .c69 {
  margin-left: 41pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1.08;
  text-align: justify;
  margin-right: 6pt;
}
.terms-and-conditions .c65 {
  margin-left: 41pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.08;
  text-align: justify;
  margin-right: 6pt;
}
.terms-and-conditions .c72 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: normal;
}

.terms-and-conditions .c33 {
  margin-left: 5pt;
  padding-top: 8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.terms-and-conditions .c56 {
  margin-left: 5pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.terms-and-conditions .c63 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.terms-and-conditions .c37 {
  margin-left: 41pt;
  padding-top: 8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.terms-and-conditions .c58 {
  margin-left: 41pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1.08;
  text-align: left;
}

.terms-and-conditions .c41 {
  padding-top: 4.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.terms-and-conditions .c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.06;
  text-align: left;
}
.terms-and-conditions .c62 {
  background-color: #ffffff;
  max-width: 452pt;
  padding: 72pt 72pt 72pt 72pt;
}
.terms-and-conditions .c0 {
  padding: 0;
  margin: 0;
}
.terms-and-conditions .c3 {
  color: inherit;
  text-decoration: inherit;
}
.terms-and-conditions .c59 {
  font-weight: 400;
}
.terms-and-conditions .title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.terms-and-conditions .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
.terms-and-conditions li {
  color: #000000;
  font-size: 11pt;
  margin-left: 23pt;
  padding-top: 8pt;
  padding-left: 18pt;
  padding-bottom: 0pt;
  line-height: 1.08;
  text-align: justify;
  margin-right: 6pt;
}
.terms-and-conditions p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
}
.terms-and-conditions h1 {
  padding-top: 8pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.terms-and-conditions h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.terms-and-conditions h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.terms-and-conditions h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.terms-and-conditions h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.terms-and-conditions h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
