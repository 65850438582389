body {
  top: 0 !important;
}

.skiptranslate.goog-te-gadget {
  display: none !important;
}

.skiptranslate {
  display: none !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

#goog-gt-tt {
  display: none !important;
}

.priceTag,
.singlePagePriceTag {
  position: relative;
}
.MuiTypography-body1.priceTag span.priceCent,
.MuiTypography-body1 .priceTag span.priceCent {
  position: absolute;
  font-size: 12px;
  top: 1px;
}
.MuiTypography-body1.singlePagePriceTag span.priceCent {
  position: absolute;
  font-size: 18px;
  top: 2px;
}
.MuiTypography-body2.priceTag span.priceCent {
  position: absolute;
  font-size: 8px;
  top: 1px;
}

/** Simplebar */
.simplebar-scrollbar::before {
  background-color: #475569;
}

/** Google Icon */
div[class*='VIpgJd'] {
  display: none !important;
  opacity: 0 !important;
}

.custom-slider .slick-list {
  padding: 5px;
}

.custom-slider .slick-next:before,
.slick-prev:before {
  content: none !important;
}
